import React, { useState } from 'react';

import logo from '../../assets/logo.png';
import blog from '../../assets/blog.png';
import fb from '../../assets/fb.png';
import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';
import splash from '../../assets/splash.png';


import axios from 'axios';

import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const submit = async () => {
        localStorage.clear();
        await axios.post(`${process.env.REACT_APP_API_URL}apEnergy/signin`, {
            username: userName,
            password: password
        }).then((res) => {
            const resp = res.data;
            if (resp.success && resp.data.auth) {
               // history.replace("/");
               localStorage.setItem("apUserToken", resp.data.auth);
               localStorage.setItem("apUsername", resp.data.username);
               navigate("/home");
            }
            else{
                alert("Invalid Credentials")
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    return (

        <>

            <div className='flex flex-row justify-center items-center'>
                <div className='hidden lg:block bg-gray-100 w-full h-screen '>
                    <div className='w-full h-full flex'>
                        <img src={splash} className='w-80 h-80 m-auto' />
                    </div>
                </div>


                {/* form */}
                <div className='p-10 flex flex-col items-center w-full h-screen'>

                    <div className='lg:hidden'>
                        <img src={logo} className='w-48 h-20 lg:w-56 lg:h-24' />
                    </div>

                    <h1 className='text-teal-600 font-bold my-5 text-3xl'>Login</h1>

                    <div className='flex flex-col items-center gap-4'>

                        <div>
                            <label className='text-base'>Username</label>
                            <br />
                            <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setUserName(e.target.value) }} />
                        </div>

                        <div>
                            <label className='text-base'>Password</label>
                            <br />
                            <input type="password" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' onChange={(e) => { setPassword(e.target.value) }} />
                        </div>


                        {/* <p className='text-teal-600 font-medium text-left'>Forgot Password?</p> */}

                        <button className='mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-40' onClick={submit}>Login</button>

                        <p>Trouble logging in?</p>

                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <a href='https://apenergy.com/our-blog/' target='blank'>
                            <img src={blog} className="w-10 h-10" />
                            </a >
                            <a href='https://m.facebook.com/apenergyefficiency' target='blank' >
                            <img src={fb} className="w-10 h-10" />
                            </a>
                            <a href='https://www.instagram.com/accounts/login/?next=/apenergyefficiency/' target='blank' >
                            <img src={instagram} className="w-10 h-10" />
                            </a>
                            <a href='https://www.linkedin.com/company/air-power-usa/' target='blank'>
                            <img src={linkedin} className="w-10 h-10" />
                            </a>
                        </div>
                    </div>


                </div>

            </div>


        </>


    )
}

export default Login