import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';

const query = new URLSearchParams(window.location.search);

const DetailInventory = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/view-location-history/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
      }
    })
      .then((res) => {
        // console.log(res.data.data);
        if (res && res.data.success) {
          setData(res.data.data.record);
        }
      }).catch((err) => {
        console.log(err);
      })
  }, [id]);
  return (
    <>
      <div className="mt-8 relative overflow-x-auto overflow-y-auto px-4">
        <table className="w-full  mt-4 px-4 border-2 text-sm text-left text-gray-500">
          <thead className=" border-2">
            <tr className=" border-2">
              <th scope="col" className="px-3 py-2 border-2 text-center">
                Location
              </th>

              <th scope="col" className="px-3 py-2 border-2  text-center">
                Date
              </th>

              {/* <th scope="col" className="px-3 py-2 border-2  text-center">
                Location
              </th>

              <th scope="col" className="px-3 py-2 border-2  text-center">
                Assigned To
              </th>

              <th scope="col" className="px-3 py-2 border-2 text-center">
                Last Calibration Date
              </th>

              <th scope="col" className="px-3 py-2 border-2 text-center " >
                Date/Time of Record
              </th>

              <th scope="col" className="px-3 py-2 border-2 text-center ">
                Notes
              </th> */}
            </tr>
          </thead>
          <tbody>
            {data.map((item, idx) => {
              return (
                <tr
                  className="bg-white border-2 cursor-pointer"
                  key={idx}
                >
                  <td
                    scope="row"
                    className="px-3 py-2 border-2 font-medium text-gray-900  whitespace-nowrap text-center"
                  >
                    {item.location}
                  </td>

                  <td
                    scope="row"
                    className="px-3 py-2 border-2 font-medium text-gray-900  whitespace-nowrap text-center"
                  >
                    {new Date(item.date).toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>


    </>
  )
}

export default DetailInventory;