import React, { Fragment, useState, useEffect } from "react";

import axios from "axios";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const EquipmentIdOldRecords = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_API_URL}equipment/equipment-id-entries/`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
    //     }
    //   })
    //   .then((res) => {
    //     console.log(res.data.data.data)
    //     setPages(res.data.data.totalPages);
    //     setCurrentPage(res.data.data.currentPage);
    //     setData(res.data.data.data.reverse());
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });
    fetchData(1);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const getSearchTerm = (e) => {
    // searchHandler(e.target.value);
    searchData(e.target.value);
  };

  const searchData = (data) => {
    setSearchTerm(data);
    axios.get(`${process.env.REACT_APP_API_URL}equipmentIdForm/search-equipment?query=${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
      }
    }).then((res) => {
      const resp = res.data;
      if (resp && resp.success) {
        setSearchResult(resp.data);
      }
    }).catch(err => {

    })
  }

  const searchHandler = (e) => {
    setSearchTerm(e);
    if (e.length !== "") {
      //console.log(e);
      const newPerformersList = data.filter((performer) => {
        return Object.values(performer)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      });
      setSearchResult(newPerformersList);
      //console.log(newPerformersList);
    } else {
      setSearchResult(data);
    }
  }

  const showAlert = (meter_number, location) => {
    if (window.confirm('Are you sure to update charging-in?')) {
      chargingIn(meter_number, location)
    };
  }

  const chargingIn = async (meter_number, location) => {
    let date = new Date().toLocaleDateString();
    let time = new Date().toLocaleTimeString();

    await axios.put(`${process.env.REACT_APP_API_URL}equipment/charging-in/${meter_number}`, {
      date: `${date} ${time}`,
      location: location
    })
      .then(res => {
        // console.log(res.data);
        if (res && res.data.success) {
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      }).catch((err) => {
        console.log(JSON.stringify(err));
        alert('Oops! Something went wrong');
      })
  }

  let [isOpen, setIsOpen] = useState(false);

  //add new
  let [image, setImage] = useState('');
  function closeModal() {
    setImage('');
    setIsOpen(false);
  }

  function openModal(img) {
    setImage(img);
    setIsOpen(true);
  }

  const fetchData = (page) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}equipmentIdForm/equipment-id-entries?page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
        }
      })
      .then((res) => {
        const resp = res.data;
        console.log(resp);
        if (resp && resp.success) {
          setPages(resp.data.totalPages);
          setCurrentPage(resp.data.currentPage);
          setData(resp.data.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  const details=(id)=>{
    navigate(`/equipment/edit/${id}`);
  }

  return (
    <>
      <div className="w-full h-[6vh] text-white bg-teal-600 flex flex-col justify-center px-2">
        <p className="text-[20px]">Records</p>
      </div>
      <div className="flex flex-col items-center mt-5">
        <input
          className='h-12 border-2 border-teal-600 rounded-lg text-center w-5/12'
          placeholder='Search Here'
          onChange={getSearchTerm}
        />
      </div>
      <div className="w-full px-4">
        <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
          {
            searchTerm.length <= 1 ?
              <>
                {data.map((item, idx) => {
                  return (
                    <Disclosure key={idx} as="div" className="mt-2">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex flex-col w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-medium text-teal-800 hover:bg-teal-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-100 focus-visible:ring-opacity-75"
                          onClick={()=>{details(item._id)}}
                          >
                            <p>{item.customer_name}, {item.city}, {item.state}</p>
                            <p>User: {item.username}</p>
                            <p>Primary Customer Contact: {item.primary_contact}</p>
                            <p>Date time of record: {new Date(item.date).toLocaleString()}</p>
                          </Disclosure.Button>
                          {/* <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                            <table className="w-full border-2 text-center text-gray-500">
                              <thead className="border-2">
                              <tr className="border-2 text-[12px]">
                                <th scope="col" className="px-3 py-2 border-2 w-[15%]">
                                  Meter#
                                </th>

                                <th scope="col" className="px-3 py-2 border-2 w-[20%]">
                                  Image
                                </th>
                                <th scope="col" className="px-3 py-2 border-2 w-[20%]">
                                  Notes
                                </th>
                                <th scope="col" className="px-3 py-2 border-2 w-[15%]">
                                  Equipment Details
                                </th>
                                <th scope="col" className="px-3 py-2 border-2 w-[30%]">
                                  Charging-In
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              {item.qr_data.map((item2, idx2) => {
                                return (
                                  <tr className="bg-white border-2 text-[9px]" key={idx2}>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[15%]"
                                    >
                                      {item2.meter_number}
                                    </td>
                                    <td scope="row" className="px-3 py-2 border-2 text-gray-900 text-center w-[20%]">
                                      {
                                        item2 && item2.image ?
                                          <img src={item2.image} onClick={() => { openModal(item2.image) }} />
                                          :
                                          `No Image`
                                      }
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[20%]"
                                    >
                                      {item2.notes}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[15%]"
                                    >
                                      {item2.compMakeModel}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[30%]"
                                    >
                                      <div className="hover:cursor-pointer">
                                        <p onClick={() => { showAlert(item2.meter_number, 'Columbus Storage') }}>Columbus Storage</p>
                                      </div>
                                      <div className="hover:cursor-pointer">
                                        <p onPress={() => { showAlert(item2.meter_number, 'Arkansas Storage') }}>Arkansas Storage</p>
                                      </div>
                                      <div className="hover:cursor-pointer">
                                        <p onPress={() => { showAlert(item2.meter_number, 'Chicago Storage') }}>Chicago Storage</p>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              </tbody>
                            </table>
                          </Disclosure.Panel> */}
                        </>
                      )}
                    </Disclosure>
                  );
                })}
              </>
              :
              <>
                {searchResult.map((item, idx) => {
                  return (
                    <Disclosure key={idx} as="div" className="mt-2"
                    onClick={()=>{details(item._id)}}
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex flex-col w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-medium text-teal-800 hover:bg-teal-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-100 focus-visible:ring-opacity-75">
                            <p>{item.customer_name}, {item.city}, {item.state}</p>
                            <p>User: {item.technician_name}</p>
                            <p>Primary Customer Contact: {item.primary_contact}</p>
                            <p>Date time of record: {new Date(item.date).toLocaleString()}</p>
                          </Disclosure.Button>
                          {/* <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                            <table className="w-full border-2 text-center text-gray-500">
                              <thead className="border-2">
                              <tr className="border-2 text-[12px]">
                                <th scope="col" className="px-3 py-2 border-2 w-[15%]">
                                  Meter#
                                </th>

                                <th scope="col" className="px-3 py-2 border-2 w-[20%]">
                                  Image
                                </th>
                                <th scope="col" className="px-3 py-2 border-2 w-[20%]">
                                  Notes
                                </th>
                                <th scope="col" className="px-3 py-2 border-2 w-[15%]">
                                  Equipment Details
                                </th>
                                <th scope="col" className="px-3 py-2 border-2 w-[30%]">
                                  Charging-In
                                </th>
                              </tr>
                              </thead>
                            <tbody>
                              {item.qr_data.map((item2, idx2) => {
                                return (
                                  <tr className="bg-white border-2 text-[9px]" key={idx2}>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[15%]"
                                    >
                                      {item2.meter_number}
                                    </td>
                                    <td scope="row" className="px-3 py-2 border-2 text-gray-900 text-center w-[20%]">
                                      {
                                        item2 && item2.image ?
                                          <img src={item2.image} />
                                          :
                                          `No Image`
                                      }
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[20%]"
                                    >
                                      {item2.notes}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[15%]"
                                    >
                                      {item2.compMakeModel}
                                    </td>
                                    <td
                                      scope="row"
                                      className="px-3 py-2 border-2 text-gray-900 text-center w-[30%]"
                                    >
                                      <div className="hover:cursor-pointer">
                                        <p onClick={() => { showAlert(item2.meter_number, 'Columbus Storage') }}>Columbus Storage</p>
                                      </div>
                                      <div className="hover:cursor-pointer">
                                        <p onPress={() => { showAlert(item2.meter_number, 'Arkansas Storage') }}>Arkansas Storage</p>
                                      </div>
                                      <div className="hover:cursor-pointer">
                                        <p onPress={() => { showAlert(item2.meter_number, 'Chicago Storage') }}>Chicago Storage</p>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              </tbody>
                            </table>
                          </Disclosure.Panel> */}
                        </>
                      )}
                    </Disclosure>
                  );
                })}
              </>
          }
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-8/12 h-[70vh] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex justify-center items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >

                  </Dialog.Title>
                  <div>
                    {
                      image && image.length ?
                        <img src={image} className='h-[60vh]' />
                        :
                        <></>
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className='flex justify-center gap-12 mt-10 mb-10'>
        {
          currentPage !== 1 ?
            <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage - 1) }}>Prev</button>
            :
            <></>
        }
        {
          currentPage !== pages ?
            <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage + 1) }}>Next</button>
            :
            <></>
        }
      </div>
    </>
  );
};

export default EquipmentIdOldRecords;
