import React, { useState, useEffect } from "react";
import { BsFillCameraFill, BsFillTrashFill } from "react-icons/bs";
import { BiEdit, BiSolidEditAlt, BiCloudUpload } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const stateData = [
    { label: 'Alabama', value: 'Alabama' },
    { label: 'Alaska', value: 'Alaska' },
    { label: 'Arizona', value: 'Arizona' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'California', value: 'California' },
    { label: 'Colorado', value: 'Colorado' },
    { label: 'Connecticut', value: 'Connecticut' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Illinois', value: 'Illinois' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Kansas', value: 'Kansas' },
    { label: 'Kentucky', value: 'Kentucky' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Maine', value: 'Maine' },
    { label: 'Maryland', value: 'Maryland' },
    { label: 'Massachusetts', value: 'Massachusetts' },
    { label: 'Michigan', value: 'Michigan' },
    { label: 'Minnesota', value: 'Minnesota' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Missouri', value: 'Missouri' },
    { label: 'Montana', value: 'Montana' },
    { label: 'Nebraska', value: 'Nebraska' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'New Hampshire', value: 'New Hampshire' },
    { label: 'New Jersey', value: 'New Jersey' },
    { label: 'New Mexico', value: 'New Mexico' },
    { label: 'New York', value: 'New York' },
    { label: 'North Carolina', value: 'North Carolina' },
    { label: 'North Dakota', value: 'North Dakota' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Oregon', value: 'Oregon' },
    { label: 'Pennsylvania', value: 'Pennsylvania' },
    { label: 'Rhode Island', value: 'Rhode Island' },
    { label: 'South Carolina', value: 'South Carolina' },
    { label: 'South Dakota', value: 'South Dakota' },
    { label: 'Tennessee', value: 'Tennessee' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Vermont', value: 'Vermont' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Washington', value: 'Washington' },
    { label: 'West Virginia', value: 'West Virginia' },
    { label: 'Wisconsin', value: 'Wisconsin' },
    { label: 'Wyoming', value: 'Wyoming' }
]

const EditEquipmentForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const d = new Date().toISOString;
    //alert('this page is in process')
    const username = localStorage.getItem("apUsername");
    const [equipmentData, setEquipmentData] = useState({});
    const fetchData = () => {
        if (id) {
            axios.get(`${process.env.REACT_APP_API_URL}equipmentIdForm/equipment-id-entries-by-id/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
                }
            })
                .then((res) => {
                    // console.log(res.data.data)
                    const resp = res.data;
                    console.log(resp);
                    if (resp && resp.success) {
                        setEquipmentData(resp.data);
                        setDate(new Date(resp.data.date).toISOString());
                        setIntend_of_trip(resp.data.intent_of_trip);
                        setTecnicianName(resp.data.username);
                        setCustomer_name(resp.data.customer_name);
                        setCity(resp.data.city);
                        setState(resp.data.state);
                        setPrimary_contanct(resp.data.primary_contact);
                        setElectricianName(resp.data.electrician_name);
                        setDownload(resp.data.download_or_install);
                        setDataIntegrirtyChecked(resp.data.data_integrity_check);
                        setCasesLeft(resp.data.cases_left);
                        setTodos(resp.data.qr_data);
                    }
                })
                .catch(err => {
                    alert("Something went wrong")
                })
        } else {
            alert("no data found");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const date1 = new Date().toLocaleDateString();
    const time1 = new Date().toLocaleTimeString();

    const [todos, setTodos] = useState([]);
    const [dateQR, setDateQR] = useState(new Date().toISOString());
    const [meter, setMeter] = useState();
    const [equipmentSerialNo, setEquipmentSerialNo] = useState();
    const [compMakeModel, setCompMakeModel] = useState("");
    const [notes, setNotes] = useState("");
    const [imageUri, setImageUri] = useState("");


    const [date, setDate] = useState(new Date().toISOString());
    const [intend_of_trip, setIntend_of_trip] = useState("");
    const [technician_name, setTecnicianName] = useState(username);
    const [customer_name, setCustomer_name] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [primary_contact, setPrimary_contanct] = useState("");
    const [electrician_name, setElectricianName] = useState("");
    const [download_or_install, setDownload] = useState("");
    const [cases_left, setCasesLeft] = useState("");
    const [dataIntegrityChecked, setDataIntegrirtyChecked] = useState("")
    const [qr_data, setQrData] = useState([]);

    // post api of equipmentID form
    const Submit = () => {
        axios.put(`${process.env.REACT_APP_API_URL}equipmentIdForm/update-equipment-id-form/${id}`, {

            date: new Date(date).toISOString(),
            intent_of_trip: intend_of_trip,
            username: technician_name,
            customer_name: customer_name,
            city: city,
            state: state,
            primary_contact: primary_contact,
            electrician_name: electrician_name,
            download_or_install: download_or_install,
            cases_left: cases_left,
            data_integrity_check: dataIntegrityChecked,
            qr_data: todos
        },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
                }
            }
        ).then((res) => {
            //console.log(res);
            const resp = res.data;
            if (resp && resp.success) {
                alert("Form Submitted Successfully");
                navigate("/home/equipment");
            } else {
                alert("Failed to update data")
            }

        }).catch((err) => {
            alert("Oops! Something went wrong");
            console.log(err);
        })
    }



    const addTodo = () => {
        let x = Math.random();
        const newTodo = {
            id: x,
            meter_number: meter,
            date: new Date(dateQR).toISOString(),
            compMakeModel: compMakeModel,
            notes: notes,
            image: imageUri
        };

        if (!edit) {
            setTodos([...todos, newTodo]);
        } else {
            const updatedData = todos.map(item => {
                if (item.id === editId) {
                    return { ...item, meter_number: meter, ap_equipment_id_number: equipmentSerialNo, date: new Date(dateQR).toISOString(), compMakeModel: compMakeModel, notes: notes, image: imageUri };
                }
                return item;
            })
            setTodos(updatedData);
            // console.log(todos);
            setEditId(0);
            setEdit(false);
        }
        // setTodos([...todos, newTodo]);
        setMeter('');
        setEquipmentSerialNo('');
        setDateQR(new Date());
        setCompMakeModel("");
        setNotes("");
        setImageUri("");
    }


    const deleteTodo = (id) => {
        const updatedData = todos.filter((item) => item.id !== id);
        setTodos(updatedData);
    }
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(0);

    const editTodo = (id) => {
        if (!edit) {
            setEditId(item => id);
            setEdit(true);
            let index = todos.findIndex(item => item.id === id);
            setMeter(todos[index].meter_number);
            setEquipmentSerialNo(todos[index].ap_equipment_id_number);
            setDateQR(todos[index].date);
            setCompMakeModel(todos[index].compMakeModel);
            setNotes(todos[index].notes);
            setImageUri(todos[index].image);
        } else {
            alert("One Edit at a time");
        }
        // const updatedData = todos.filter((item) => item.id !== id);
        // setTodos(updatedData);
    }

    const [uploading, setUploading] = useState(false);
    const handleImageSelect = (event) => {
        setUploading(true);
        const files = event.target.files[0];

        if (files) {
            const formData = new FormData();
            formData.append("image", files);

            axios.post(`${process.env.REACT_APP_API_URL}uploadImage/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
                }
            }).then((res) => {
                // console.log(res.data.data.Location);
                if (res && res.data) {
                    setUploading(false);
                    setImageUri(res.data.data.Location);
                    alert("Image Uploaded Successfully");
                    // console.log("todos after :>>> ",todos);
                }
                else {
                    setUploading(false);
                    alert("Error while uploading image");
                }
            }).catch((err) => {
                // console.log(err);
                setUploading(false);
                alert("Error while uploading image");
            })
        }
    }

    const handleImageSelect2 = async (event, i) => {
        const files = event.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("image", files);

            axios.post(`${process.env.REACT_APP_API_URL}uploadImage/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
                }
            }).then((res) => {
                // console.log(res.data.data.Location);
                if (res && res.data) {
                    const updatedData = todos.map(item => {
                        if (item.id === i) {
                            // Update the specific value based on the key
                            return { ...item, image: res.data.data.Location };
                        }
                        return item;
                    });
                    // //console.log(updatedData);
                    setTodos(updatedData);
                    alert("Image Uploaded Successfully");
                    // console.log("todos after :>>> ",todos);
                }
                else {
                    alert("Error while uploading image");
                }
            }).catch((err) => {
                // console.log(err);
                alert("Error while uploading image");
            })
        }
        else {
            alert("Error while uploading image");
        }
    }
    return (
        <>


            <div className="w-full h-[6vh] text-white bg-teal-600 flex flex-col justify-center px-2">
                <p className="text-[20px]">New Equipment Id Form</p>
            </div>
            {/* form */}


            {/* form for large devices */}

            <div className="hidden lg:block">
                <div className="px-6 flex flex-col lg:flex-row  lg:justify-evenly">
                    <table className="mt-8">
                        <tr className="text-lg">
                            <td className="px-6 py-3">
                                {" "}
                                <label
                                    for="Date"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Date:
                                </label>{" "}
                            </td>
                            <td className="px-6 py-3">
                                {new Date(date).toLocaleString()}
                            </td>
                        </tr>
                        <tr className="text-lg">
                            <td className="px-6 py-3">
                                <label
                                    for="intend of trip"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Intend of Trip:
                                </label>
                            </td>
                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={intend_of_trip}
                                    onChange={(e) => { setIntend_of_trip(e.target.value) }}
                                />
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="technician name"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Technician Name:
                                </label>
                            </td>

                            <td className="px-6 py-3" >
                                {technician_name}
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="customer name"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Customer Name:
                                </label>
                            </td>

                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={customer_name}
                                    onChange={(e) => { setCustomer_name(e.target.value) }}
                                />
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="city"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    City:
                                </label>
                            </td>
                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={city}
                                    onChange={(e) => { setCity(e.target.value) }}
                                />
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="state"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    State:
                                </label>
                            </td>
                            <td className="px-6 py-3">
                                {/* <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={state}
                                    onChange={(e) => { setState(e.target.value) }}
                                /> */}
                                <select
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    onChange={(e) => { if (e.target.value == 'Select State') { setState('') } else { setState(e.target.value) } }}
                                    value={state}
                                >
                                    <option>Select State</option>
                                    {
                                        stateData.map((item, idx) => (
                                            <option key={idx} value={item.value}>
                                                {item.value}
                                            </option>
                                        ))
                                    }
                                </select>
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="primary contact"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Primary Contact:
                                </label>
                            </td>
                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={primary_contact}
                                    onChange={(e) => { setPrimary_contanct(e.target.value) }}
                                />
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="primary contact"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Electrician Name:
                                </label>
                            </td>
                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={electrician_name}
                                    onChange={(e) => { setElectricianName(e.target.value) }}
                                />
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="cases left"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Data Integrity Checked:
                                </label>
                            </td>
                            <td className="px-6 py-3 flex gap-2">
                                <div>
                                    <input
                                        type="radio"
                                        name="x"
                                        value="yes"
                                        class="bg-gray-50 border border-gray-300 p-2.5 "
                                        checked={dataIntegrityChecked === "yes"}
                                        onChange={(e) => { setDataIntegrirtyChecked(e.target.value) }}
                                    /> <span>Yes</span>
                                    <input
                                        type="radio"
                                        name="x"
                                        value="no"
                                        class="bg-gray-50 border border-gray-300 p-2.5 "
                                        checked={dataIntegrityChecked === "no"}
                                        onChange={(e) => { setDataIntegrirtyChecked(e.target.value) }}
                                    /> <span>no</span>
                                </div>
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3">
                                <label
                                    for="cases left"
                                    class="block mb-2 text-base font-medium text-gray-900 "
                                >
                                    Cases Left:
                                </label>
                            </td>
                            <td className="px-6 py-3 flex gap-2">
                                <div>
                                    <input
                                        type="radio"
                                        name="y"
                                        value="yes"
                                        class="bg-gray-50 border border-gray-300 p-2.5 "
                                        checked={cases_left === "yes"}
                                        onChange={(e) => { setCasesLeft(e.target.value) }}
                                    /> <span>Yes</span>
                                    <input
                                        type="radio"
                                        name="y"
                                        value="no"
                                        class="bg-gray-50 border border-gray-300 p-2.5 "
                                        checked={cases_left === "no"}
                                        onChange={(e) => { setCasesLeft(e.target.value) }}
                                    /> <span>no</span>
                                </div>
                            </td>
                        </tr>

                        <tr className="text-base">
                            <td className="px-6 py-3 w-32">
                                <label className="block mb-2 text-base font-medium text-gray-900 ">
                                    {" "}
                                    Download/Installation/Removal Notes (e.g flow meter/meter concern){" "}
                                </label>{" "}

                            </td>
                            <td className="px-6 py-3">
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 "
                                    value={download_or_install}
                                    onChange={(e) => { setDownload(e.target.value) }}
                                />
                            </td>
                        </tr>
                    </table>

                    <div>
                        {
                            todos.length ?
                                <>


                                    <table className="w-full border-2 text-sm text-left text-gray-500">
                                        <thead className=" border-2">
                                            <tr className=" border-2">
                                                <th scope="col" className="px-3 py-2 border-2">
                                                    AP Barcode No.
                                                </th>
                                                <th scope="col" className="px-3 py-2 border-2">
                                                    Meter Serial No.
                                                </th>
                                                <th scope="col" className="px-3 py-2 border-2">
                                                    Date time of Record
                                                </th>

                                                <th scope="col" className="px-3 py-2 border-2">
                                                    Equipment Details
                                                </th>

                                                <th scope="col" className="px-3 py-2 border-2">
                                                    Image
                                                </th>
                                                <th scope="col" className="px-3 py-2 border-2">
                                                    Edit / Delete
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {todos.map((item, idx) => {
                                                return (
                                                    <tr className="bg-white border-2 " key={idx}>
                                                        <td
                                                            scope="row"
                                                            className="px-3 py-2 border-2 font-medium text-gray-900 text-center"
                                                        >
                                                            {item.meter_number}
                                                        </td>

                                                        <td
                                                            scope="row"
                                                            className="px-3 py-2 border-2 font-medium text-gray-900 text-center"
                                                        >
                                                            {item.ap_equipment_id_number}
                                                        </td>

                                                        <td className="px-3 border-2 py-2 text-center">
                                                            {new Date(item.date).toLocaleString()}
                                                        </td>

                                                        <td className="px-3  border-2 py-2 text-center">
                                                            {item.compMakeModel}
                                                        </td>

                                                        <td className="px-3 border-2 py-2 text-center">
                                                            {
                                                                item && item.image ?
                                                                    <img src={item.image} className="h-[70px] w-[70px]" />
                                                                    :
                                                                    <>
                                                                        <input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            id="fileInput3"
                                                                            onChange={(e) => { handleImageSelect2(e, item.id) }}
                                                                            style={{ display: 'none' }}
                                                                        />

                                                                        <label htmlFor="fileInput3" className="uploadIcon">
                                                                            <BiCloudUpload size={20} />
                                                                        </label>
                                                                    </>

                                                            }
                                                        </td>
                                                        <td className="px-3 py-2 flex flex-row justify-center items-center gap-4">
                                                            <BiEdit size={20} className="hover:cursor-pointer" onClick={() => { editTodo(item.id) }} />
                                                            <MdDelete size={20} className="hover:cursor-pointer" onClick={() => { deleteTodo(item.id) }} />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>


                                </>

                                :
                                <div></div>
                        }

                        <table className="mt-8">
                            <tr className="text-base">
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        AP Barcode No.{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">
                                    <input
                                        type="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
                                        value={meter}
                                        onChange={(e) => { setMeter(e.target.value) }}
                                    />
                                </td>
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        Equipment Serial No.{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">
                                    <input
                                        type="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
                                        value={equipmentSerialNo}
                                        onChange={(e) => { setEquipmentSerialNo(e.target.value) }}
                                    />
                                </td>
                            </tr>

                            <tr className="text-base">
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        Date time of Record{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">
                                    {new Date(dateQR).toLocaleString()}
                                </td>
                            </tr>

                            <tr className="text-base">
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        Enter Compressor Details{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">
                                    <input
                                        type="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
                                        value={compMakeModel}
                                        onChange={(e) => { setCompMakeModel(e.target.value) }}
                                    />
                                </td>
                            </tr>

                            <tr className="text-base">
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        Notes{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">
                                    <input
                                        type="text"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
                                        value={notes}
                                        onChange={(e) => { setNotes(e.target.value) }}
                                    />
                                </td>
                            </tr>

                            <tr className="text-base">
                                <td className="px-6 py-3 w-32">
                                    <label className="block mb-2 text-base font-medium text-gray-900 ">
                                        {" "}
                                        Image{" "}
                                    </label>{" "}


                                </td>
                                <td className="px-6 py-3">

                                    {
                                        imageUri && imageUri.length ?
                                            <img src={imageUri} className="h-[100px] w-[100px]" />
                                            :
                                            <>
                                                {
                                                    !uploading ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                id="fileInput"
                                                                accept="image/*"
                                                                onChange={handleImageSelect}
                                                                style={{ display: 'none' }}
                                                            />

                                                            <label htmlFor="fileInput" className="uploadIcon">
                                                                <BiCloudUpload size={20} />
                                                            </label>
                                                        </>
                                                        :
                                                        <>
                                                            <p>Uploading...</p>
                                                        </>
                                                }
                                            </>
                                    }

                                </td>
                            </tr>
                            <tr >
                                <td className="px-6 py-3">
                                    <button className="mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-32" onClick={addTodo}>Add more</button>
                                </td>

                            </tr>
                        </table>
                    </div>


                </div>

                <div className="flex flex-row items-center justify-center">
                    <div className="px-10 my-10">
                        <button className="mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-32"
                            onClick={Submit}
                        >Save</button>
                    </div>

                    {/* <div className="px-10 my-10">
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@apenergy.com&su=EquipmentID Form details%20Email&body=&atturl=window.print()">
            <button className="mt-10  bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-32"
            >Send Email </button>
          </a>
        </div> */}
                </div>
            </div>


            {/* form for small devices */}

            <div className="lg:hidden">
                <div className="flex flex-col items-center gap-4 px-10">

                    <div className="flex flex-row items-center justify-between w-full md:w-1/2 mt-5">
                        <label className='text-base'>Date: </label>
                        <p className="text-center">{new Date(date).toLocaleString()}</p>
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>Intend of trip: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' value={intend_of_trip} onChange={(e) => { setIntend_of_trip(e.target.value) }} />
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>User: </label>
                        <p className="text-center">{technician_name}</p>
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>Customer Name: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' value={customer_name} onChange={(e) => { setCustomer_name(e.target.value) }} />
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>City: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' value={city} onChange={(e) => { setCity(e.target.value) }} />
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>State: </label>
                        {/* <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' value={state} onChange={(e) => { setState(e.target.value) }} /> */}
                        <select
                            className='rounded-md border-gray-400 h-10 focus:ring-teal-600'
                            onChange={(e) => { if (e.target.value == 'Select State') { setState('') } else { setState(e.target.value) } }}
                            value={state}
                        >
                            <option>Select State</option>
                            {
                                stateData.map((item, idx) => (
                                    <option key={idx} value={item.value}>
                                        {item.value}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>Primary Contact: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' value={primary_contact} onChange={(e) => { setPrimary_contanct(e.target.value) }} />
                    </div>

                    <div className="flex flex-row items-center justify-between w-full mt-5 md:w-1/2">
                        <label className='text-base'>Electrician Name: </label>
                        <input type="text" className='rounded-md border-gray-400 h-10 focus:ring-teal-600' value={electrician_name} onChange={(e) => { setElectricianName(e.target.value) }} />
                    </div>

                    <div className="md:w-1/2">
                        <label>
                            Download / Installation / Removal Notes ( e.g. flow meter / meter concern ):
                        </label>
                        <input type="text" className='rounded-md border-gray-400 h-15 focus:ring-teal-600 w-full' value={download_or_install} onChange={(e) => { setDownload(e.target.value) }} />
                    </div>

                    <div className="flex flex-row justify-between w-full md:w-1/2">
                        <label
                            for="cases left"
                            class="block mb-2 text-base font-medium text-gray-900 "
                        >
                            Data Integrity Checked:
                        </label>
                        <div className="flex gap-1">
                            <input
                                type="radio"
                                name="a"
                                value="yes"
                                class="bg-gray-50 border border-gray-300 p-2.5 "
                                checked={dataIntegrityChecked === "yes"}
                                onChange={(e) => { setDataIntegrirtyChecked(e.target.value) }}
                            /> <span>Yes</span>

                            <input
                                type="radio"
                                name="a"
                                value="no"
                                class="bg-gray-50 border border-gray-300 p-2.5 "
                                checked={dataIntegrityChecked === "no"}
                                onChange={(e) => { setDataIntegrirtyChecked(e.target.value) }}
                            /> <span>no</span>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between w-full md:w-1/2">
                        <label
                            for="cases left"
                            class="block mb-2 text-base font-medium text-gray-900 "
                        >
                            Cases Left:
                        </label>
                        <div className="flex gap-1">
                            <input
                                type="radio"
                                name="b"
                                value="yes"
                                class="bg-gray-50 border border-gray-300 p-2.5 "
                                checked={cases_left === "yes"}
                                onChange={(e) => { setCasesLeft(e.target.value) }}
                            /> <span>Yes</span>

                            <input
                                type="radio"
                                name="b"
                                value="no"
                                class="bg-gray-50 border border-gray-300 p-2.5 "
                                checked={cases_left === "no"}
                                onChange={(e) => { setCasesLeft(e.target.value) }}
                            /> <span>no</span>
                        </div>
                    </div>

                    {
                        todos.length !== 0 ?
                            <>
                                <table className="w-full border-2 text-center text-gray-500">
                                    {/* <thead className="border-2"> */}
                                    <tr className="border-2 text-[12px]">
                                        <th scope="col" className="py-2 border-2 w-[15%] text-[8px]">
                                            AP Barcode No.
                                        </th>
                                        <th scope="col" className="py-2 border-2 w-[15%] text-[8px]">
                                            Meter Serial No.
                                        </th>

                                        <th scope="col" className="py-2 border-2 w-[20%] text-[8px]">
                                            Date time of Record
                                        </th>
                                        <th scope="col" className="py-2 border-2 w-[20%] text-[8px]">
                                            Equipment Details
                                        </th>
                                        <th scope="col" className="py-2 border-2 w-[15%] text-[8px]">
                                            Notes
                                        </th>
                                        <th scope="col" className="py-2 border-2 w-[30%] text-[8px]">
                                            Edit/Delete
                                        </th>
                                    </tr>
                                    {/* </thead>
                            <tbody> */}
                                    {todos.map((item, idx) => {
                                        return (
                                            <tr className="bg-white border-2 text-[9px]" key={idx}>
                                                <td
                                                    scope="row"
                                                    className="py-2 border-2 text-gray-900 text-center w-[15%]"
                                                >
                                                    {item.meter_number}
                                                </td>
                                                <td
                                                    scope="row"
                                                    className="py-2 border-2 text-gray-900 text-center w-[20%]"
                                                >
                                                    {new Date(item.date).toLocaleString()}
                                                </td>
                                                <td
                                                    scope="row"
                                                    className="py-2 border-2 text-gray-900 text-center w-[15%]"
                                                >
                                                    {item.compMakeModel}
                                                </td>
                                                <td scope="row" className="py-2 border-2 text-gray-900 text-center w-[20%]">
                                                    {
                                                        item && item.image ?
                                                            <img src={item.image} />
                                                            :
                                                            <>

                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    id="fileInput2"
                                                                    onChange={(e) => { handleImageSelect2(e, item.id) }}
                                                                    style={{ display: 'none' }}
                                                                />

                                                                <label htmlFor="fileInput2" className="uploadIcon">
                                                                    <BiCloudUpload size={20} />
                                                                </label>
                                                            </>
                                                    }
                                                </td>
                                                <td scope="row" className="py-2 border-2 text-gray-900 text-center w-[20%]">
                                                    <div className="flex justify-center items-center">
                                                        <BiEdit size={20} className="hover:cursor-pointer" onClick={() => { editTodo(item.id) }} />
                                                        <MdDelete size={20} className="hover:cursor-pointer" onClick={() => { deleteTodo(item.id) }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {/* </tbody> */}
                                </table>
                            </>
                            :
                            <>

                            </>
                    }

                    <div className="w-full border-black flex flex-col justify-center items-center" style={{ border: '5px solid black' }}>

                        <div className="flex flex-col items-center mt-5 md:w-1/2">
                            <label className="font-sans font-bold mb-2">
                                AP Barcode No.
                            </label>
                            <input type="text" className='rounded-md border-gray-400 h-8 focus:ring-teal-600 w-11/12' value={meter} onChange={(e) => { setMeter(e.target.value) }} />
                        </div>
                        <div className="flex flex-col items-center mt-5 md:w-1/2">
                            <label className="font-sans font-bold mb-2">
                                Meter Serial No.
                            </label>
                            <input type="text" className='rounded-md border-gray-400 h-8 focus:ring-teal-600 w-11/12' value={equipmentSerialNo} onChange={(e) => { setEquipmentSerialNo(e.target.value) }} />
                        </div>

                        <div className="flex flex-col items-center mt-5 md:w-1/2">
                            <label className="font-sans font-bold mb-2" >
                                Date time of Record
                            </label>
                            {new Date(dateQR).toLocaleString()}
                        </div>

                        <div className="flex flex-col items-center mt-5 md:w-1/2">
                            <label className="font-sans font-bold mb-2" >
                                Enter Compressor Details
                            </label>
                            <input type="text" className='rounded-md border-gray-400 h-8 focus:ring-teal-600 w-11/12' value={compMakeModel} onChange={(e) => { setCompMakeModel(e.target.value) }} />
                        </div>

                        <div className="flex flex-col items-center mt-5 md:w-1/2">
                            <label className="font-sans font-bold mb-2">
                                Notes
                            </label>
                            <input type="text" className='rounded-md border-gray-400 h-8 focus:ring-teal-600 w-11/12' value={notes} onChange={(e) => { setNotes(e.target.value) }} />
                        </div>
                        <div className="px-6 py-3 w-32 flex items-center justify-center gap-4">
                            <label className="block text-base font-medium text-gray-900">
                                {" "}
                                Image: {" "}
                            </label>{" "}

                            {
                                imageUri && imageUri.length ?
                                    <img src={imageUri} className="h-[100px] w-[100px]" />
                                    :
                                    <>
                                        {
                                            !uploading ?
                                                <>
                                                    <input
                                                        type="file"
                                                        id="fileInput4"
                                                        accept="image/*"
                                                        onChange={handleImageSelect}
                                                        style={{ display: 'none' }}
                                                    />

                                                    <label htmlFor="fileInput4" className="uploadIcon">
                                                        <BiCloudUpload size={20} />
                                                    </label>
                                                </>
                                                :
                                                <>
                                                    <p>Uploading...</p>
                                                </>
                                        }
                                    </>
                            }
                        </div>
                    </div>

                    <div className="w-full mb-4">
                        <button className="w-[45%] bg-teal-600 text-white h-12 px-2 py-1" onClick={addTodo}>Add</button>
                        <button className="w-[10%] text-white h-12 px-2 py-1"></button>
                        <button className="w-[45%] bg-teal-600 text-white h-12 px-2 py-1" onClick={Submit}>Save</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditEquipmentForm;
