import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Home from './screens/User/Home';
import Login from './screens/User/Login';
import EquipmentForm from './screens/User/EquipmentForm';
import HomeEquipment from './screens/User/HomeEquipment';
import HomeInventory from './screens/User/HomeInventory';

import EquipmentIdOldRecords from './screens/User/EquipmentIdOldRecords';
import InventoryData from './screens/User/InventoryData';
import DetailInventory from './screens/User/DetailInventory';
import Graphs from './screens/User/Graphs';
import NotFound from './NotFound';
import MyEntries from './screens/User/MyEntries';
import AllInventory from './screens/User/AllInventory';
import EditEquipmentForm from './screens/User/EditEquipmentForm';

export default function App() {
    return (
        <>
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Login />} />
                <Route path="/home/equipment" element={<HomeEquipment />} />
                <Route path='/home/inventory' element={<HomeInventory />} />
                <Route path="/equipmentForm" element={<EquipmentForm />} />
                <Route path='/equipment/edit/:id' element={<EditEquipmentForm />} />
            {/* <Route path="/newEquipmentForm" element={<NewEquipmentForm/>}/> */}
            <Route path="/equipment/oldRecords" element={<EquipmentIdOldRecords />} />
                <Route path="/equipment/myEntries" element={<MyEntries />} />
                <Route path="/inventory/data" element={<InventoryData />} />
                <Route path='/inventory/allInventory' element={<AllInventory />} />
                <Route path="/inventory" element={<DetailInventory />} />
                <Route path="/inventory/:id" element={<DetailInventory />} />
                <Route path="/inventory/graphs" element={<Graphs />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            {/* <div className='h-[100vh] w-[100vw] flex items-center justify-center'>
                <h1 className='text-[36px]'>This Side is under maintainance Download the APenergy APP</h1>
            </div> */}
        </>
    )
}