import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";

import { MdAdd } from "react-icons/md";

function AddNewInventory(props) {
  let [isOpen, setIsOpen] = useState(false);

  //add new
  function closeModal() {
    window.location.reload();
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const [inventoryTypes, setInventoryTypes] = useState([]);
  useEffect(() => {
    fetchInventoryType();
  }, []);

  const fetchInventoryType = () => {
    axios.get(`${process.env.REACT_APP_API_URL}admin/inventoryType/getInventoryTypePhone`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
      }
    }).then((res) => {
      console.log(res.data.data);
      const resp = res.data;
      console.log(resp);
      if (resp && resp.success) {
        setInventoryTypes(resp.data);
      }
    }).catch(err => {

    })
  }

  const [aPInventoryBarcode, setAPInventoryBarcode] = useState("");
  const [aPEquipmentIDNumber, setAPEquipmentIDNumber] = useState("");
  const [type, setType] = useState("");
  const [dateTimeOfRecord, setdateTimeOfRecord] = useState(new Date());
  const [assignedTo, setAssignedTo] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [lastCalibrationDate, setLastCalibrationDate] = useState("");
  const [notes, setNotes] = useState("");
  const [customerName, setCustomerName] = useState("");

  const Submit = () => {
    if(type == 'Select Equipment Type' || !type || !aPInventoryBarcode || !aPEquipmentIDNumber){
      alert("Enter proper fields");
      return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}inventoryManagement/add-inventory`, {

      ap_inventory_barcode: aPInventoryBarcode,
      ap_equipment_id_number: aPEquipmentIDNumber,
      type: type,
      date: dateTimeOfRecord,
      assigned_to: assignedTo,
      customer_name: customerName,
      city: currentLocation,
      last_calibration_date: lastCalibrationDate,
      notes: notes,

    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("apUserToken")}`
      }
    }).then((res) => {
      console.log(res);
      const resp = res.data;
      if (resp && resp.success) {
        closeModal();
        alert("Inventory Added");
      } else {
        alert("Failed to Add Inventory")
      }
    }).catch((err) => {
      console.log(err);
    })
  }


  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className=""
        >
          <MdAdd />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add new Inventory
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="grid gap-6 mb-6 lg:grid-cols-2">
                      <div>
                        <label
                          for="name"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          AP Barcode No.
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setAPInventoryBarcode(e.target.value) }}
                        />
                      </div>
                      <div>
                        <label
                          for="email"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Meter Serial No.
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setAPEquipmentIDNumber(e.target.value) }}
                        />
                      </div>
                      <div>
                        <label
                          for="phone"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Equipment Type
                        </label>
                        <select onChange={(e) => setType(e.target.value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                        >
                          <option>Selete Equipment Type</option>
                          {
                            inventoryTypes && inventoryTypes.length && <>
                              {
                                inventoryTypes.map((item, idx) => (
                                  <option>
                                    {item.value}
                                  </option>
                                ))
                              }
                            </>
                          }
                        </select>
                        {/* <input
                          type="test"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          onChange={(e) => { setType(e.target.value) }}
                        /> */}
                      </div>
                      <div>
                        <label
                          for="username"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Date
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          value={new Date(dateTimeOfRecord).toLocaleString()}
                          disabled={true}
                          onChange={(e) => { setdateTimeOfRecord(e.target.value) }}
                        />
                      </div>

                      <div>
                        <label
                          for="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Assigned To
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setAssignedTo(e.target.value) }}
                        />
                      </div>

                      <div>
                        <label
                          for="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          Customer Name
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setCustomerName(e.target.value) }}
                        />
                      </div>

                      <div>
                        <label
                          for="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900"
                        >
                          City
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setCurrentLocation(e.target.value) }}
                        />
                      </div>


                      <div>
                        <label
                          for="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Lat Calibration Date
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setLastCalibrationDate(e.target.value) }}
                        />
                      </div>

                      <div>
                        <label
                          for="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                          Notes
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                          onChange={(e) => { setNotes(e.target.value) }}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white"
                      onClick={Submit}
                    >
                      Add
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AddNewInventory;