import React from 'react';
import { AiOutlineForm } from "react-icons/ai";
import { BsGraphUpArrow } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const HomeInventory = () => {
  return (

    <>
    <div className='flex flex-col md:flex-row gap-8 items-center justify-center mt-10'>
        <NavLink to="/inventory/data" className='flex flex-row gap-4 w-3/4 md:w-64 px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center'>
          <AiOutlineForm /> <span>Inventory By Customer</span>
        </NavLink>

        <NavLink to="/inventory/allInventory" className='flex flex-row gap-4 w-3/4 md:w-64 px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center'>
          <AiOutlineForm /> <span>All Inventory</span>
        </NavLink>

        {/* <NavLink className='flex flex-row gap-4 w-3/4 md:w-64  px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center'  >
          <BsGraphUpArrow /> <span>Graphs</span>
        </NavLink> */}
    </div>
    </>
    
  )
}

export default HomeInventory;