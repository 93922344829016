import React from 'react';
import { BsRecordBtn } from "react-icons/bs";
import { AiFillFolderOpen } from "react-icons/ai";
import { NavLink } from 'react-router-dom';

const HomeEquipment = () => {
  return (

    <>
      <div className='flex flex-col md:flex-row gap-8 items-center justify-center mt-10'>
        <NavLink to='/equipmentForm' className='flex flex-row gap-3  md:w-64 px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center w-9/12'>
          <BsRecordBtn /> <span>New Equipment ID Form</span>
        </NavLink>
        <NavLink to='/equipment/myEntries' className='flex flex-row gap-3  md:w-64  px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center w-9/12'>
          <AiFillFolderOpen /> <span>My Entries</span>
        </NavLink>
        <NavLink to='/equipment/oldRecords' className='flex flex-row gap-3  md:w-64  px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center w-9/12'>
          <AiFillFolderOpen /> <span>All Entries</span>
        </NavLink>
      </div>
    </>

  )
}

export default HomeEquipment;