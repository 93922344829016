import React from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineForm } from "react-icons/ai";
import { MdOutlineInventory } from "react-icons/md";

const Home = () => {
  return (

    <>
      {/* <div className='h-screen w-full flex flex-col md:flex-row gap-8 items-center justify-center'>

        <NavLink to='/home/equipment'>
          <button className='flex flex-row gap-4 w-3/4 md:w-64 px-4 py-2 bg-teal-600 text-white items-center rounded-md'>
            <AiOutlineForm /> <span>Equipment Id form</span>
          </button>
        </NavLink>


        <NavLink to='/home/inventory'>
          <button className='flex flex-row gap-4 w-3/4 md:w-64  px-4 py-2 bg-teal-600 text-white items-center rounded-md'  >
            <MdOutlineInventory /> <span>Inventory Management</span>
          </button>
        </NavLink>

      </div> */}

      <div className='flex flex-col md:flex-row gap-8 items-center justify-center mt-10'>
          <NavLink to='/home/equipment' className='flex flex-row gap-4 w-3/4 md:w-64 px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center'>
            <AiOutlineForm /> <span>Equipment ID Form</span>
        </NavLink>
          <NavLink to='/home/inventory' className='flex flex-row gap-4 w-3/4 md:w-64  px-4 py-2 bg-teal-600 text-white items-center rounded-md justify-center'  >
            <MdOutlineInventory /> <span>Inventory Management</span>
        </NavLink>
      </div>
    </>

  )
}

export default Home