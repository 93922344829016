import axios from 'axios';
import React, { useState, useEffect } from 'react';

const Graphs = () => {
    return (
        <>
            <div className='w-full h-[6vh] bg-teal-600 flex flex-col justify-center px-2'>
                <p className='text-[20px]'>Graphs</p>
            </div>
            <div>
          <div class="block items-center">
            <label
              class=" text-sm font-medium text-gray-900 dark:text-gray-400"
              for="IsAnonymous"
            >
              Meter Type
            </label>
            <select
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l block w-3/12 p-2.5 mt"
              name="IsAnonymous"
              //onChange={casesLeftHandler}
              required
            >
              <option>Meter Type</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        </>
    )
}

export default Graphs;