import axios from 'axios';
import React, { Component, useState, useEffect } from 'react';
import { MdOutlineRefresh, MdAdd } from "react-icons/md";
import { BiSort } from "react-icons/bi";

import { Disclosure } from "@headlessui/react";
import { useNavigate } from 'react-router-dom';
export default function AllInventory() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const fetchData = (page) => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/inventory-management-data?page=${page}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apUserToken")}`,
            }
        }).then((res) => {
            const resp = res.data;
            if (resp && resp.success) {
                setPages(Number(resp.data.totalPages));
                setCurrentPage(Number(resp.data.currentPage));
                setLoading(false);
                setData(resp.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        fetchData(1);
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const getSearchTerm = (e) => {
        //searchHandler(e.target.value);
        searchData(e.target.value);
    };

    const searchData = (data) => {
        setSearchTerm(data);
        axios.get(`${process.env.REACT_APP_API_URL}inventoryManagement/search-inventory?query=${data}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("apUserToken")}`,
            }
        }).then((res) => {
            if (res && res.data && res.data.success) {
                // console.log(res.data.data);
                setSearchResult(res.data.data);
            }
        }).catch(err => {

        })
    }

    const searchHandler = (e) => {
        setSearchTerm(e);
        if (e.length !== "") {
            //console.log(e);
            const newPerformersList = data.filter((performer) => {
                return Object.values(performer)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            setSearchResult(newPerformersList);
            //console.log(newPerformersList);
        } else {
            setSearchResult(data);
        }
    };

    const [sort, setSort] = useState(true)
    const sortData = () => {
        data.reverse();
        if (sort === true) {
            setSort(false);
        } else {
            setSort(true);
        }
    }

    const details = (id) => {
        navigate(`/inventory/${id}`);
    }
    return (
        <>
            <div className="w-full h-[6vh] text-white bg-teal-600 flex flex-row items-center px-2">
                <div className="w-9/12">
                    <p className="text-[20px]">Inventory</p>
                </div>
                <div className="w-4/12 gap-4 flex flex-row justify-end font-medium items-center">
                    <div>
                        <MdOutlineRefresh onClick={fetchData} className='hover:cursor-pointer' />
                    </div>
                    <div>
                        <BiSort onClick={sortData} className='hover:cursor-pointer' />
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center mt-5">
                <input
                    className="h-12 border-2 border-teal-600 rounded-lg text-center w-5/12"
                    placeholder="Search Here"
                    onChange={getSearchTerm}
                />
            </div>
            {
                !loading ?
                    <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                        {
                            searchTerm.length < 1 ?
                                <>
                                    {
                                        data.map((item, idx) => (
                                            <Disclosure key={idx} as="div" className="mt-2">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="flex flex-col w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-medium text-teal-800 hover:bg-teal-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-100 focus-visible:ring-opacity-75 hover:cursor-pointer" onClick={() => { details(item.ap_equipment_id_number) }}>
                                                            <div className="items-center justify-between w-full">
                                                                <p>AP Barcode No.: {item.ap_inventory_barcode}</p>
                                                                <p>Assigned to: {item.assigned_to}</p>
                                                                <p className='text-[12px]'>Meter Serial No.: {item.ap_equipment_id_number}</p>
                                                                <p className='text-[12px]'>Type: {item.type}</p>
                                                                <p className='text-[12px]'>Type: {item.customer_name}</p>
                                                                <p className='text-[12px]'>Current Location: {item.city}</p>
                                                                <p className='text-[12px]'>Date: {new Date(item.date).toLocaleString()}</p>
                                                            </div>
                                                        </Disclosure.Button>
                                                    </>
                                                )}
                                            </Disclosure>
                                        ))
                                    }
                                </>
                                :
                                <>
                                    {
                                        searchResult.map((item, idx) => (
                                            <Disclosure key={idx} as="div" className="mt-2">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="flex flex-col w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-base font-medium text-teal-800 hover:bg-teal-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-100 focus-visible:ring-opacity-75 hover:cursor-pointer" onClick={() => { details(item.ap_equipment_id_number) }}>
                                                            <div className="items-center justify-between w-full">
                                                                <p>AP Inventory Barcode: {item.ap_inventory_barcode}</p>
                                                                <p>Assigned to: {item.assigned_to}</p>
                                                                <p className='text-[12px]'>Serial Number: {item.ap_equipment_id_number}</p>
                                                                <p className='text-[12px]'>Type: {item.type}</p>
                                                                <p className='text-[12px]'>Type: {item.customer_name}</p>
                                                                <p className='text-[12px]'>Current Location: {item.city}</p>
                                                                <p className='text-[12px]'>Date: {item.date}</p>
                                                            </div>
                                                        </Disclosure.Button>
                                                    </>
                                                )}
                                            </Disclosure>
                                        ))
                                    }
                                </>
                        }
                    </div>
                    :
                    <div className="text-center">
                        <svg
                            role="status"
                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
            }
            <div className='flex justify-center gap-12 mt-10 mb-10'>
                {
                    currentPage !== 1 ?
                        <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage - 1) }}>Prev</button>
                        :
                        <></>
                }
                {
                    currentPage !== pages ?
                        <button className="bg-teal-600 border-teal-600 hover:bg-teal-700 hover:border-teal-700 rounded-md text-white px-4 py-2 font-medium w-25" onClick={() => { fetchData(currentPage + 1) }}>Next</button>
                        :
                        <></>
                }
            </div>
        </>
    )
}